import React from "react"
import * as styles from "./SharedLayout.module.scss"
import CenterSquareBg from "../../images/CenterSquareBg.png"
import BlueCenterSquareBg from "../../images/BlueCenterSquareBg.png"
import PinkStripeLTR from "../../images/PinkStripeLTR.png"
import PinkStripeRTL from "../../images/PinkStripeRTL.png"
import cn from "classnames"

interface SharedLayoutProps {
  title: string
  blueCenter: boolean
  body: JSX.Element
}

export const SharedLayout = ({
  title,
  body,
  blueCenter = false,
}: SharedLayoutProps) => {
  return (
    <div className={styles.sharedLayoutContainer}>
      <div
        className={cn(styles.sharedLayoutCenterBg, {
          [styles.sharedLayoutBlueCenter]: blueCenter,
        })}
      >
        <h1>{title}</h1>
        <img
          src={blueCenter ? BlueCenterSquareBg : CenterSquareBg}
          alt="Center Square"
        />
      </div>
      <div
        className={cn(styles.sharedLayoutBackground, {
          [styles.sharedLayoutBlueBackground]: blueCenter,
        })}
      >
        <img src={PinkStripeLTR} alt="Pink Stripe" className={styles.leftImg} />
        <img
          src={PinkStripeRTL}
          alt="Pink Stripe"
          className={styles.rightImg}
        />
      </div>
      {body}
    </div>
  )
}
