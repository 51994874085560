import loadable from "@loadable/component"
import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useMemo } from "react"
import { ContentfulGalleriesPageQuery } from "../../../types/gatsby-graphql"
import { FilterComponent } from "../../components/FilterComponent/FilterComponent"
import { NFTCard } from "../../components/NFTCard/NFTCard"
import { SharedLayout } from "../../components/SharedLayout/SharedLayout"
import * as styles from "./Galleries.module.scss"
import { enricheLotWithAuction, getAuctionsIds } from "../../utils/auction"

const galleries = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data: ContentfulGalleriesPageQuery = useStaticQuery(graphql`
    query ContentfulGalleriesPage {
      allContentfulGallerySignup {
        edges {
          node {
            galleryRepresentativeFirstName
            galleryRepresentativeLastName
            galleryRepresentativeMiddleName
            contentful_id
            galleryName
            username
            profilePicture {
              gatsbyImageData(width: 75, height: 75)
              file {
                url
              }
              contentful_id
            }
            featuredArtists {
              firstName
              lastName
              middleName
              contentful_id
            }
          }
        }
      }
      allContentfulLot(filter: { auction: { contentfulid: { ne: null } } }) {
        edges {
          node {
            id
            lotId
            ended
            contentful_id
            artist {
              contentful_id
              firstName
              middleName
              lastName
              username
              coverImage {
                file {
                  url
                }
                title
                contentful_id
              }
              profilePicture {
                gatsbyImageData(width: 75, height: 75)
                file {
                  url
                }
                title
                contentful_id
              }
            }
            auction {
              ...lotAuctionFragment
            }
          }
        }
      }
    }
  `)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const transformData = useMemo(() => {
    let transformedData: any[] = []
    data.allContentfulGallerySignup.edges.forEach(gallery => {
      let _lotList: any[] = []
      gallery.node?.featuredArtists?.forEach(artist => {
        const lots = data.allContentfulLot?.edges.filter(
          lot => lot.node?.artist?.contentful_id === artist?.contentful_id
        )
        _lotList = [..._lotList, lots]
      })
      if (_lotList.length > 0) {
        transformedData = [
          ...transformedData,
          {
            ...gallery,
            lots: _lotList,
          },
        ]
      }
    })
    return transformedData
  }, [data])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const auctions = useMemo(() => {
    return getAuctionsIds(data?.allContentfulLot?.edges)
  }, [data])

  const LoadableGalleryMonitor = loadable(
    () => import("../../components/GalleryMonitor/GalleryMonitor")
  )

  return (
    <SharedLayout
      title="Galleries"
      blueCenter={false}
      body={
        <>
          <div className={styles.galleriesContainer}>
            <FilterComponent />
            {transformData.map(gallery => {
              const galleryLots = gallery?.lots?.flat()

              return (
                <div className={styles.galleriesFoundationList}>
                  <Link to={`/gallery/${gallery?.node?.contentful_id}`}>
                    <div className={styles.galleriesTitle}>
                      <img
                        src={
                          gallery?.node?.profilePicture?.gatsbyImageData?.images
                            ?.fallback?.src ??
                          gallery?.node?.profilePicture?.file?.url
                        }
                        alt={gallery?.node?.profilePicture?.contentful_id}
                      />
                      <h1>{gallery?.node?.galleryName}</h1>
                      <h3>{`(@${gallery?.node?.username})`}</h3>
                    </div>
                  </Link>
                  <LoadableGalleryMonitor
                    auctions={auctions}
                    render={({ result }) => {
                      const enrichedData = enricheLotWithAuction(
                        galleryLots || [],
                        result?.auctions
                      )

                      if (enrichedData.length === 0)
                        return (
                          <div className={styles.galleriesNFTList}>
                            <span className={styles.galleryEmptyResult}>
                              This gallery has no NFTs available
                            </span>
                          </div>
                        )

                      return (
                        <div className={styles.galleriesNFTList}>
                          {enrichedData.map((mapData: any) => {
                            return (
                              <NFTCard
                                title={mapData?.lot?.auction?.mint?.name!}
                                imgUrl={
                                  mapData?.lot?.auction?.mint?.mediaAsset?.media
                                    ?.file?.url!
                                }
                                assetType={
                                  mapData?.lot?.auction?.mint?.mediaAsset?.media
                                    ?.mimeType!
                                }
                                price={mapData?.auction?.reservePriceInETH!}
                                by={`${[
                                  mapData?.lot?.artist?.firstName,
                                  mapData?.lot?.artist?.lastName,
                                ]
                                  .join(" ")
                                  .trimEnd()}`}
                                linkTo={`/artwork/${mapData?.lot?.contentful_id}`}
                              />
                            )
                          })}
                        </div>
                      )
                    }}
                  />
                </div>
              )
            })}
          </div>
        </>
      }
    />
  )
}

export default galleries
