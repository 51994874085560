import React from "react"
import SearchIcon from "../../Icon/SearchIcon"
import * as styles from "./FilterComponent.module.scss"

export const FilterComponent = () => {
  return (
    <div className={styles.filterComponent}>
      {/* <select>
        <option>Sort by: Recent</option>
        <option>Sort by: Older</option>
        <option>Sort by: Cheapest</option>
        <option>Sort by: Expensive</option>
      </select>
      <div className={styles.searchInput}>
        <input type="text" />
        <button onClick={() => null}>
          <SearchIcon fill="gray" />
        </button>
      </div> */}
    </div>
  )
}
